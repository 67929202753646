<template>
    <v-dialog v-model="show" @keydown.esc="onCancel()" persistent max-width="800">
        <v-card class="fill-height" fluid>
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="onCancel">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title> {{ $t("Escaneie o QR-CODE") }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="text-center pa-8" v-if="show">
                <v-progress-circular v-if="loading" size="140" width="12" color="primary" indeterminate />
                <v-alert icon="error" prominent text dense type="error" class="my-12" v-if="errorMessage"> <b>Erro:</b> {{ errorMessage }} </v-alert>
                <div>
                    <p>{{ error }}</p>
                </div>
                <qrcode-stream @decode="onDecode" @init="onInit"></qrcode-stream>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>


<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
    components: { QrcodeStream },

    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            dialog: false,
            errorMessage: null,
            loading: false,
        };
    },

    methods: {
        onCancel() {
            this.$emit("close");
        },
        async onInit(promise) {
            this.loading = true;
            try {
                const { capabilities } = await promise;
                console.log(capabilities);
                this.errorMessage = null;
            } catch (error) {
                if (error.name === "NotAllowedError") {
                    this.errorMessage = "Permissão de uso da câmera pelo usuário negada";
                } else if (error.name === "NotFoundError") {
                    this.errorMessage = "O dispositivo da câmera não esta instalado corretamente";
                } else if (error.name === "NotSupportedError") {
                    this.errorMessage = "A página não pode ser aberta em HTTPS (ou localhost)";
                } else if (error.name === "NotReadableError") {
                    this.errorMessage = "Talvez a câmera já esteja em uso";
                } else if (error.name === "OverconstrainedError") {
                    this.errorMessage = "Você solicitou a permissão da câmera e ainda não permitiu?";
                } else if (error.name === "StreamApiNotSupportedError") {
                    this.errorMessage = "O navegador que esta sendo utilizado, parece não suportar a função";
                }
            } finally {
                this.loading = false;
            }
        },
        onDecode(decodedString) {
            this.$emit("onRead", decodedString);
        },
    },
};
</script>