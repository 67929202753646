<template>
    <v-card-text :class="`${secondColor}--text`" v-if="minutesConnected != null && minutesConnected >= 0 && show">
        <div class="headline mb-sm-2" v-if="minutesConnected > 60">
            <v-icon :color="secondColor">history</v-icon>

            {{ dateTime.getHours(minutesConnected) }}
            <small v-if="dateTime.getHours(minutesConnected) > 1">horas</small>
            <small v-else>hora</small>

            <small> e </small>{{ dateTime.getMinutes(minutesConnected) }}

            <small v-if="dateTime.getMinutes(minutesConnected) > 1">minutos</small>
            <small v-else>minuto</small>
        </div>
        <div class="headline mb-sm-2" v-else-if="minutesConnected >= 0">
            <v-icon :color="secondColor">history</v-icon>
            {{ minutesConnected }}
            <small v-if="minutesConnected > 1">minutos</small>
            <small v-else>minuto</small>
        </div>
        {{ title }}
    </v-card-text>
</template>

<script>
import dateTime from "@/helpers/DateTime";

export default {
    props: {
        minutesConnected: {
            required: true,
        },
        secondColor: {
            required: true,
        },
        title: {
            required: true,
        },
        show: {
            required: true,
        },
    },

    data() {
        return {
            dateTime,
        };
    },
}
</script>
