export default {
    showMinutes(minutes) {
        if (minutes > 1) {
            return `${minutes} minutos`;
        } else if (minutes == 1) {
            return `${minutes} minuto`;
        } else {
            return `-`;
        }
    },
    getHours(minutes) {
        return parseInt(minutes / 60);
    },
    getMinutes(minutes) {
        return (minutes % 60);
    },
    tranformTime(minutes) {
        return `${parseInt(minutes / 60)
            .toString()
            .padStart(2, "0")}h ${parseInt(minutes % 60)
                .toString()
                .padStart(2, "0")}min`;
    },
    tranformDate(_date) {
        let date = _date.split("-");
        return `${date[2].padStart(2, "0")}/${date[1].padStart(2, "0")}/${date[0]
            }`;
    },
    getInterval(date1, date2) {
        return ((new Date(date2).getTime() - new Date(date1).getTime()) / (1000 * 3600 * 24)) + 1;
    }
};