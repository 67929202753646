<template>
    <div>
        <v-footer :padless="padless" fixed v-if="slimMode == true">
            <v-row justify="center" class="mt-1 mb-0">
                <p class="font-weight-medium mr-6">
                    Declaro que li a <a href="#" @click="slimMode = false">política de privacidade.</a>
                </p>

                <v-btn color="success" small @click="save">
                    <v-icon dark left>check_circle</v-icon>
                    Aceitar
                </v-btn>
            </v-row>
        </v-footer>
        <v-row justify="center" v-else>
            <v-dialog v-model="dialog" persistent scrollable width="100vw">
                <v-card height="80vh">
                    <v-card-title class="white--text primary">Política de Privacidade</v-card-title>
                    <v-card-text style="background: white">
                        <REV01 />
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn color="success" @click="save">
                            <v-icon dark left>check_circle</v-icon>
                            Aceitar os termos
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import REV01 from "@/components/shared/Privacy-rev01";

export default {
    data() {
        return {
            dialog: true,
            slimMode: true
        };
    },

    components: {
        REV01,
    },

    methods: {
        save() {
            // $store.getters.menuMode == 'USER'
            this.$store.commit("ACCEPT_PRIVACY");
            this.dialog = false;
        },
    },
};
</script>