import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import VueI18n from 'vue-i18n'
import dictionary from '@/plugins/i18n'
import App from './App'
import api from '@/helpers/api'
import store from '@/helpers/store'
import router from '@/helpers/router'
import eventHub from './eventHub'
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

import './registerServiceWorker'

const moment = require('moment')

try {
    const firebaseConfig = {
        apiKey: "AIzaSyDhf-vSf6juqyxnnbFF7z5HiqmUFqAO-us",
        authDomain: "incharge-1e487.firebaseapp.com",
        databaseURL: "https://incharge-1e487.firebaseio.com",
        projectId: "incharge-1e487",
        storageBucket: "incharge-1e487.appspot.com",
        messagingSenderId: "398347046740",
        appId: "1:398347046740:web:e5c8a1b090e84b965942c1",
        measurementId: "G-H6P8289WZJ"
    };
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    getToken(messaging, { vapidKey: "BCTipYml-mrq8y6YTFF_TXmn9aC-teqr41YGVHcL6FSvhb3l0T8IJ2l5i5KzYJKFwdcvUrXhWyHzmgymyKVgxDI" }).then((notification) => {
        store.commit('SET_NOTIFICATION', notification);
    });
} catch (error) {
    store.commit('CLEAR_NOTIFICATION');
    if (navigator.userAgent.toLowerCase().indexOf("mobile") != -1) {
        window.console.error('Error on detect message to firebase:', error);
    }
}

Vue.prototype.$eventHub = eventHub;

Vue.prototype.$http = api;

Vue.config.productionTip = false;

Vue.use(VueI18n);

const i18n = new VueI18n(dictionary)

if (store.getters.getLang == "en") {
    require('moment/locale/en-ca')
} else if (store.getters.getLang == "es") {
    require('moment/locale/es')
} else {
    require('moment/locale/pt')
}

Vue.use(require('vue-moment'), {
    moment
});


new Vue({
    i18n,
    vuetify,
    render: h => h(App),
    router,
    store,
}).$mount('#app')