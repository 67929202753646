<template>
    <v-container class="mx-0 mt-4" fluid>
        <v-alert outlined color="info" :prominent="$vuetify.breakpoint.smAndUp" border="top" icon="school" v-if="status == 'Available'">
            <div class="title">Dica:</div>
            É sua primeira vez? Fique tranquilo, aqui vai uma ajudinha ; )
            <br />
            <br />
            <h2>Conecte o carro ao carregador.</h2>
            <br />
            Colocamos um cabo do tamanho ideal para você conseguir conectar o seu carro sem transtorno, nossos pontos de recarga são pensados para uma melhor experiência,
            caso tenha alguma dificuldade tente reposicionar seu carro.
        </v-alert>
        <v-alert outlined color="info" :prominent="$vuetify.breakpoint.smAndUp" border="top" icon="school" v-if="status == 'Preparing'">
            <div class="title">Dica:</div>
            Estamos muito felizes por você ter conseguido conectar o carro. Vou te dar mais uma ajudinha...
            <br />
            <br />
            <div v-if="polity == 'PUBLIC'">Basta clicar no botão <b>"Reconhecer o carregamento"</b> acima.</div>
            <div v-if="isPay"><b>Efetue o pagamento para continuarmos.</b></div>
            <div v-else>Basta clicar no botão <b>"Ativar"</b> acima.</div>
        </v-alert>
        <v-alert outlined color="info" :prominent="$vuetify.breakpoint.smAndUp" border="top" icon="school" v-if="status == 'Charging'">
            <div class="title">Dica:</div>
            Uhuu! Não esquece de ativar as notificações para você ficar sabendo quando o carregamento terminar.
            <br />
            <br />
            <b v-if="polity == 'PUBLIC'">Posso te fazer um pedido? Ao finalizar o carregamento libere o carregador para o próximo usuário.</b>
        </v-alert>
    </v-container>
</template>

<script>

export default {
    props: {
        status: {
            type: String,
            required: true,
        },
        polity: {
            type: String,
            required: true,
        },
        isPay: {
            type: Boolean,
        },
    },
}
</script>
