import Home from '@/pages/Home';
import Now from '@/pages/Now';

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/helpers/store'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Home,
        meta: {}
    },
    {
        path: '/privacy',
        component: () => import('@/pages/Privacy'),
        meta: {}
    },
    {
        path: '/terms',
        component: () => import('@/pages/Terms'),
        meta: {}
    },
    {
        path: '/receipt',
        component: () => import('@/pages/Receipt'),
        meta: {}
    },

    // USER ROLES
    {
        path: '/now/:charger',
        component: Now,
        meta: {
            // role: 'ROLE_USER',
        }
    },
    {
        path: '/now/:charger/:plug',
        component: Now,
        meta: {
            // role: 'ROLE_USER',
        }
    },
    {
        path: '/payment/:charger/:plug/:user/:price/:plataform',
        component: () => import('@/pages/Now/PayFeedback'),
        meta: {
            // role: 'ROLE_USER',
        }
    },
    {
        path: '/payment/:charger/:plug/:user/:price/:plataform/:paymentStatus',
        component: () => import('@/pages/Now/PayFeedback'),
        meta: {
            // role: 'ROLE_USER',
        }
    },

    {
        path: '*', component: Home
    }
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

router.beforeEach((routeTo, routeFrom, next) => {
    store.commit("FIRST_REQUEST", true);

    if (routeTo.path == "/login") {
        store.state.routeBeforeLogin = routeFrom.path;
    }

    // Enter here if route has a role to access
    if (routeTo.meta && routeTo.meta.role) {
        try {
            // If user not logged or not contains permission, send to home page
            if (store.getters.verifyRole(routeTo.meta.role) == false) {
                return next({ path: routeTo.meta.redirectToWhenNotPermit ? routeTo.meta.redirectToWhenNotPermit : "/" });
            }
        } catch (e) {
            return next({ path: "/" });
        }
    }
    next()
})

export default router