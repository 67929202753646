<template>
    <div>
        <div v-if="mercadoPago && stripe">
            <v-tabs v-model="tab" :background-color="backgroundColor" color="primary" centered>
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab key="1">
                    Stripe
                </v-tab>
                <v-tab key="2">
                    Mercado Pago
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="text-center">
                <v-tab-item key="1">
                    <v-card flat :color="backgroundColor">
                        <v-row align="center" justify="center" align-content="space-between">
                            <ButtonStripe :price="price" :plugNumber="plug" :chargerName="chargerName" :userId="userId" />
                        </v-row>
                    </v-card>
                </v-tab-item>
                <v-tab-item key="2">
                    <v-card flat :color="backgroundColor">
                        <v-row align="center" justify="center" align-content="space-between">
                            <ButtonMercadoPago :price="price" :plugNumber="plug" :chargerName="chargerName" :userId="userId" />
                        </v-row>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <div v-else>
            <v-row align="center" justify="center" align-content="space-between" v-if="mercadoPago">
                <ButtonMercadoPago :price="price" :plugNumber="plug" :chargerName="chargerName" :userId="userId" />
            </v-row>
            <v-row align="center" justify="center" align-content="space-between" v-if="stripe">
                <ButtonStripe :price="price" :plugNumber="plug" :chargerName="chargerName" :userId="userId" />
            </v-row>
        </div>
    </div>
</template>

<script>
import ButtonMercadoPago from "@/components/shared/Payment/ButtonMercadoPago";
import ButtonStripe from "@/components/shared/Payment/ButtonStripe";

export default {
    name: "PaymentOptions",

    components: {
        ButtonMercadoPago,
        ButtonStripe
    },

    props: {
        chargerName: {
            required: true,
        },
        price: {
            required: true,
        },
        plug: {
            required: true,
        },
        backgroundColor: {
            type: String,
            default: "warning",
        },
    },

    mounted() {
        this.getUser();
        this.getPayOption();
        this.$eventHub.$on("makeLogin", this.getUser);
    },

    data() {
        return {
            tab: null,
            userId: null,
            mercadoPago: false,
            stripe: false,
        };
    },

    methods: {
        getUser() {
            if (this.$store.state.user.ID) {
                this.userId = this.$store.state.user.ID;
            } else {
                this.userId = "NO_USER";
            }
        },
        getPayOption() {
            this.$http
                .get("api/v2/payment-detail/" + this.chargerName)
                .then((result) => {
                    if (result) {
                        this.stripe = result.stripe;
                        this.mercadoPago = result.mercadoPago;
                    }
                });
        }
    }
};
</script>