<template>
    <div class="text-xs-center d-print-none">

        <v-bottom-sheet v-model="showInfo">
            <v-footer padless>
                <v-card class="flex" flat tile>
                    <v-card-title class="primary">
                        <strong class="subheading white--text">pay4charge</strong>

                        <v-spacer></v-spacer>

                        <v-btn class="mx-2 green" dark icon :href="contactHref" target="_blank">
                            <v-icon size="24px">fab fa-whatsapp</v-icon>
                        </v-btn>
                        <v-btn class="mx-2" dark icon href="https://www.instagram.com/incharge_app/" target="_blank">
                            <v-icon size="24px">fab fa-instagram</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="primary mb-0 pb-0 white--text">
                        <div>Rua Dalton Luiz Telles, 111 - Monte Belo</div>
                        <div>Santa Rita do Sapucaí - MG</div>
                        <div><v-icon size="20px" left dark>call</v-icon>+55 (35) 3473-0824</div>
                        <div><v-icon size="20px" left dark>email</v-icon>help@incharge.app</div>
                    </v-card-text>
                    <v-card-text class="primary">
                        <v-layout wrap>
                            <v-flex text-center xs12 pb-2>
                                <router-link to="/receipt" tag="v-btn">
                                    <v-btn outlined x-small dark @click="showInfo = false">Recibos de carregamento</v-btn>
                                </router-link>
                            </v-flex>
                            <v-flex xs6 text-right pr-2>
                                <router-link to="/privacy" tag="v-btn">
                                    <v-btn outlined x-small right dark @click="showInfo = false">Política de Privacidade</v-btn>
                                </router-link>
                            </v-flex>
                            <v-flex xs6 pl-2>
                                <router-link to="/terms" tag="v-btn">
                                    <v-btn outlined x-small dark @click="showInfo = false">Termos e Condições</v-btn>
                                </router-link>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                    <v-card-text>
                        <v-layout wrap>
                            <v-flex xs4 text-left>
                                <Version />
                            </v-flex>
                            <v-flex xs4 text-center>
                                <v-btn outlined small href="https://sdeveloper.io" target="_blank">
                                    &copy;{{ new Date().getFullYear() }} —
                                    <strong>sdeveloper.io</strong>
                                </v-btn>
                            </v-flex>
                            <v-flex xs4 text-right>
                                <v-btn fab x-small :color="$vuetify.theme.dark ? 'white' : 'black'" @click="changeDarkMode">
                                    <v-icon :color="$vuetify.theme.dark ? 'black' : 'white'">mdi-theme-light-dark</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-footer>
        </v-bottom-sheet>
    </div>
</template>

<script>
import Version from "@/components/core/Version";

export default {
    name: "Info",

    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },

    components: { Version },

    data() {
        return {};
    },

    computed: {
        contactHref() {
            return encodeURI(`https://wa.me/5511973778065?text=Estou utilizando a plataforma INCHARGE e gostaria de `);
        },
        showInfo: {
            get: function () {
                return this.show;
            },
            set: function (value) {
                if (!value) {
                    this.$emit("onClose");
                }
            },
        },
    },

    methods: {
        changeDarkMode() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            this.$store.commit("SET_DARK_MODE", this.$vuetify.theme.dark);
        },
    },
};
</script>