import store from '@/helpers/store'

import vuetify_pt from 'vuetify/lib/locale/pt'
import vuetify_en from 'vuetify/lib/locale/en'

let pt = require('../../public/static/i18n/pt.json');
let en = require('../../public/static/i18n/en.json');

const messages = {
    en: {
        $vuetify: {
            ...vuetify_en,
        },
        ...en
    },
    pt: {
        $vuetify: {
            ...vuetify_pt,
        },
        ...pt
    },
}

export default {
    locale: store.getters.getLang, // set locale
    messages, // set locale messages
}