<template>
    <!-- <div id="main_page" style="height: 100vh; width: 100vw; background-color: #0dff00"> -->
    <div id="main_page" style="height: 100vh; width: 100vw; background-color: var(--v-secondary-base)">
        <v-container id="box" style="background-color: white" class="mx-auto rounded-xl white--text" fill-height fluid>
            <v-row align="center" justify="center">
                <v-col align="center" justify="center">
                    <img class="mx-auto" id="image" :aspect-ratio="16 / 9" :src="require('@/assets/images/homeImage.png')" />
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col align="center" justify="center">
                    <v-btn @click="showQRCode = true" color="secondary" style="width: 100%" class="elevation-24" dark :small="$vuetify.breakpoint.xs"
                        :x-large="$vuetify.breakpoint.smAndUp">
                        <v-icon left dark :small="$vuetify.breakpoint.xs" :large="$vuetify.breakpoint.smAndUp" class="mr-sm-12"> qr_code_scanner </v-icon>
                        Ler QR-Code
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <QRCode :show="showQRCode" @close="showQRCode = false" @onRead="readedQRCode" />
    </div>
</template>

<script>
import QRCode from "@/components/core/QRCode";

export default {
    name: "Home",

    mounted() {
        this.update();
        this.timer = setInterval(this.update, 10000);
    },

    components: { QRCode },

    data() {
        return {
            loading: true,
            showQRCode: false,
            timer: null,
        };
    },

    methods: {
        readedQRCode(QRCode) {
            if (QRCode.startsWith(window.location.origin)) {
                this.showQRCode = false;
                window.location.href = QRCode;
            } else {
                this.$eventHub.$emit("msgError", this.$t("Código inválido."));
            }
        },
        onConnectionFailed() {
            if (this.points.length > 0) {
                this.points.forEach((point) => {
                    point.chargers.forEach((charger) => {
                        charger.status = "offline";
                    });
                });
                this.setPoints(this.points);
            }
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>

<style scoped>
@media screen and (min-width: 1280px) {
    #main_page {
        padding-top: 20vh;
    }

    #image {
        max-width: 40vw;
    }

    #box {
        height: 60vh;
        width: 50vw;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    #main_page {
        padding-top: 20vh;
    }

    #image {
        max-width: 40vw;
    }

    #box {
        height: 60vh;
        width: 60vw;
    }
}

@media screen and (min-width: 540px) and (max-width: 1023px) {
    #main_page {
        padding-top: 20vh;
    }

    #image {
        max-width: 40vw;
    }

    #box {
        height: 60vh;
        width: 80vw;
    }
}

@media screen and (max-width: 540px) {
    #main_page {
        padding-top: 20vh;
    }

    #image {
        max-width: 60vw;
    }

    #box {
        height: 60vh;
        width: 85vw;
    }
}
</style>