<template>
    <div>
        <v-snackbar v-model="snackbar.visible" :bottom="snackbar.y === 'bottom'" :left="snackbar.x === 'left'" :multi-line="snackbar.mode === 'multi-line'"
            :right="snackbar.x === 'right'" :timeout="snackbar.timeout" :top="snackbar.y === 'top'" :vertical="snackbar.mode === 'vertical'" :color="snackbar.color"
            transition="fab-transition">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn x-small class="secondary--text white" v-bind="attrs" @click="snackbar.visible = false">{{ $t("Fechar") }}</v-btn>
            </template>
        </v-snackbar>
        <v-dialog transition="dialog-bottom-transition" max-width="600" :value="modal.open">
            <template>
                <v-card>
                    <v-card-text v-for="(item, index) in modal.list" :key="index" class="py-4">
                        <v-alert type="error"> {{ item }} </v-alert>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn text @click="modal.open = false">{{ $t("Fechar") }}</v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "Message",

    data() {
        return {
            modal: {
                open: false,
                list: [],
            },
            snackbar: {
                color: "error",
                visible: false,
                y: "top",
                x: "",
                mode: "vertical",
                timeout: 10000,
                text: "-",
            },
        };
    },

    methods: {
        showTransitionSnackbar(msg, color) {
            if (this.snackbar.visible == false) {
                this.showSnackbar(msg, color);
            } else {
                this.snackbar.visible = false;
                setTimeout(() => this.showSnackbar(msg, color), 500);
            }
        },
        showSnackbar(msg, color) {
            this.snackbar.text = msg;
            this.snackbar.color = color;
            this.snackbar.visible = true;
        },
    },

    mounted() {
        this.$eventHub.$on("msgClose", () => {
            this.modal.list = [];
            this.modal.open = false;
            this.snackbar.text = "";
            this.snackbar.color = "error";
            this.snackbar.visible = false;
        });
        this.$eventHub.$on("msgError", (msg) => {
            if (Array.isArray(msg)) {
                this.modal.list = msg.map((i) => (i.message ? i.message : i));
                this.modal.open = true;
            } else {
                this.showTransitionSnackbar(msg, "error");
            }
        });
        this.$eventHub.$on("msgWarning", (msg) => {
            if (Array.isArray(msg)) {
                this.modal.list = msg.map((i) => (i.message ? i.message : i));
                this.modal.open = true;
            } else {
                this.showTransitionSnackbar(msg, "warning");
            }
        });
        this.$eventHub.$on("msgSuccess", (msg) => {
            if (Array.isArray(msg)) {
                this.modal.list = msg.map((i) => (i.message ? i.message : i));
                this.modal.open = true;
            } else {
                this.showTransitionSnackbar(msg, "success");
            }
        });
    },
};
</script>
